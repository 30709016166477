import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');

      if (!token) {
        setVerificationStatus('error');
        return;
      }

      try {
        const response = await fetch(`/api/auth/verify-email?token=${token}`);
        
        if (response.ok) {
          setVerificationStatus('success');
        } else {
          setVerificationStatus('error');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('error');
      }
    };

    verifyEmail();
  }, [location.search]);

  return (
    <div className="min-h-screen bg-background flex items-center justify-center">
      <div className="bg-component-bg p-8 rounded-lg shadow-md w-96 text-center">
        {verificationStatus === 'verifying' && (
          <p className="text-xl text-text">Verifying your email...</p>
        )}
        {verificationStatus === 'success' && (
          <>
            <h2 className="text-2xl font-bold mb-6 text-primary">Email Verified Successfully!</h2>
            <p className="mb-6 text-text">Your email has been verified. You can now log in to your account.</p>
            <button
              onClick={() => navigate('/login')}
              className="w-full bg-primary text-white font-bold py-2 px-4 rounded hover:bg-opacity-90 transition-colors"
            >
              Go to Login
            </button>
          </>
        )}
        {verificationStatus === 'error' && (
          <>
            <h2 className="text-2xl font-bold mb-6 text-red-500">Verification Failed</h2>
            <p className="mb-6 text-text">There was an error verifying your email. The link may be invalid or expired.</p>
            <button
              onClick={() => navigate('/')}
              className="w-full bg-primary text-white font-bold py-2 px-4 rounded hover:bg-opacity-90 transition-colors"
            >
              Go to Home
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
