import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { SearchIcon, BookOpenIcon, PlayIcon, CloudIcon, ServerIcon, CodeIcon, LightBulbIcon } from '@heroicons/react/outline';

// Import documentation components
import Overview from '../components/Docs/Overview';
import GettingStarted from '../components/Docs/GettingStarted';
import RestApi from '../components/Docs/RestApi';
import GrpcApi from '../components/Docs/GrpcApi';
import SdkGuides from '../components/Docs/SdkGuides';
import BestPractices from '../components/Docs/BestPractices';

const Docs = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = useMemo(() => [
    { id: 'overview', label: 'Overview', component: Overview, icon: BookOpenIcon },
    { id: 'getting-started', label: 'Getting Started', component: GettingStarted, icon: PlayIcon },
    { id: 'rest-api', label: 'REST API', component: RestApi, icon: CloudIcon },
    { id: 'grpc-api', label: 'gRPC API', component: GrpcApi, icon: ServerIcon },
    { id: 'sdk-guides', label: 'SDK Guides', component: SdkGuides, icon: CodeIcon },
    { id: 'best-practices', label: 'Best Practices', component: BestPractices, icon: LightBulbIcon },
  ], []);

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    if (tabs.some(tab => tab.id === currentPath)) {
      setActiveTab(currentPath);
    } else {
      setActiveTab('overview');
    }
  }, [location, tabs]);

  const handleTabClick = useCallback((tabId) => {
    setActiveTab(tabId);
    navigate(`/docs/${tabId}`);
    window.scrollTo(0, 0);  // Scroll to top when changing tabs
  }, [navigate]);

  const findMatches = useCallback((content, term) => {
    if (typeof content === 'string') {
      const regex = new RegExp(`(.{0,40}${term}.{0,40})`, 'gi');
      const matches = content.match(regex);
      return matches ? matches.map(match => ({ text: match })) : [];
    } else if (Array.isArray(content)) {
      return content.flatMap(child => findMatches(child, term));
    } else if (content && typeof content === 'object' && content.props) {
      return findMatches(content.props.children, term);
    }
    return [];
  }, []);

  const searchDocs = useCallback((term) => {
    const results = tabs.map(tab => {
      const content = tab.component().props.children;
      const matches = findMatches(content, term);
      return {
        id: tab.id,
        label: tab.label,
        matches: matches
      };
    }).filter(result => result.matches.length > 0);
    return results;
  }, [tabs, findMatches]);

  const handleSearch = useCallback((e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length > 2) {
      const results = searchDocs(term);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchDocs]);

  const handleSearchResultClick = useCallback((result) => {
    setIsSearchOpen(false);
    handleTabClick(result.id);
  }, [handleTabClick]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="min-h-screen bg-background"
    >
      <div className="container mx-auto px-4 py-8">
        <div className="flex items-center mb-8">
          <motion.h1
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="text-4xl font-bold text-primary mr-4"
          >
            HPKV Documentation
          </motion.h1>
          <button
            onClick={() => setIsSearchOpen(true)}
            className="flex items-center text-gray-600 hover:text-primary transition-colors"
          >
            <SearchIcon className="h-6 w-6 mr-2" />
            <span>Search...</span>
          </button>
        </div>
        <div className="flex flex-col md:flex-row">
          <nav className="w-full md:w-1/4 mb-8 md:mb-0">
            <ul>
              {tabs.map((tab, index) => (
                <motion.li
                  key={tab.id}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="mb-2"
                >
                  <button
                    onClick={() => handleTabClick(tab.id)}
                    className={`w-full text-left px-4 py-2 rounded transition-colors duration-200 flex items-center ${
                      activeTab === tab.id
                        ? 'bg-primary text-white'
                        : 'bg-component-bg text-text hover:bg-gray-200'
                    }`}
                  >
                    <tab.icon className="h-5 w-5 mr-2" />
                    {tab.label}
                  </button>
                </motion.li>
              ))}
            </ul>
          </nav>
          <main className="w-full md:w-3/4 md:pl-8">
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                {tabs.map((tab) => (
                  <Route
                    key={tab.id}
                    path={`/${tab.id}`}
                    element={
                      <motion.div
                        initial={{ x: 20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -20, opacity: 0 }}
                        transition={{ duration: 0.3 }}  // Slow down the fade in/slide in to match fade out
                      >
                        <tab.component />
                      </motion.div>
                    }
                  />
                ))}
                <Route path="/" element={<Overview />} />
              </Routes>
            </AnimatePresence>
          </main>
        </div>
      </div>
      <AnimatePresence>
        {isSearchOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            onClick={() => setIsSearchOpen(false)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="bg-white rounded-lg shadow-xl w-full max-w-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-4">
                <input
                  type="text"
                  placeholder="Search documentation..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  autoFocus
                />
              </div>
              {searchResults.length > 0 && (
                <ul className="max-h-96 overflow-y-auto border-t border-gray-200">
                  {searchResults.map((result, index) => (
                    <li key={`${result.id}-${index}`} className="border-b border-gray-200 last:border-b-0">
                      <button
                        onClick={() => handleSearchResultClick(result)}
                        className="w-full text-left p-4 hover:bg-gray-100 transition-colors"
                      >
                        <h3 className="font-semibold text-primary">{result.label}</h3>
                        {result.matches.slice(0, 3).map((match, matchIndex) => (
                          <p key={matchIndex} className="text-sm text-gray-600 mt-1">
                            ...{match.text}...
                          </p>
                        ))}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Docs;
