import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { HomeIcon, KeyIcon, ChartBarIcon, CreditCardIcon, CogIcon } from '@heroicons/react/outline';

const sidebarItems = [
  { name: 'Overview', icon: HomeIcon, path: '/dashboard' },
  { name: 'API Keys', icon: KeyIcon, path: '/dashboard/api-keys' },
  { name: 'Usage', icon: ChartBarIcon, path: '/dashboard/usage' },
  { name: 'Billing', icon: CreditCardIcon, path: '/dashboard/billing' },
  { name: 'Settings', icon: CogIcon, path: '/dashboard/settings' },
];

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [activeItem, setActiveItem] = useState('Overview');
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedCompanyName, setEditedCompanyName] = useState('');
  const navigate = useNavigate();

  const fetchUserData = useCallback(async (token) => {
    try {
      const response = await fetch('/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setUser(data);
      setEditedName(data.name);
      setEditedCompanyName(data.company_name || '');
    } catch (error) {
      setError('Failed to fetch user data');
      if (error.message.includes('401')) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      fetchUserData(token);
    }
  }, [navigate, fetchUserData]);

  const handleSaveProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/auth/update-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ 
          name: editedName, 
          companyName: editedCompanyName || 'Not specified' // Provide a default value if empty
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update profile');
      }

      const data = await response.json();
      setUser({ ...user, name: editedName, company_name: editedCompanyName });
      setIsEditing(false);
      console.log(data.message); // Log success message
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile: ' + error.message);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-md">
        <div className="p-4">
          <h2 className="text-xl font-semibold text-gray-800">Dashboard</h2>
        </div>
        <nav className="mt-4">
          {sidebarItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${
                activeItem === item.name ? 'bg-gray-200' : ''
              }`}
              onClick={() => setActiveItem(item.name)}
            >
              <item.icon className="w-5 h-5 mr-2" />
              {item.name}
            </Link>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 overflow-auto">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">{activeItem}</h1>
          </div>
        </header>
        <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Content for each section */}
          {activeItem === 'Overview' && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">User Profile</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
                </div>
                {!isEditing && (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="bg-black text-white font-bold py-2 px-4 rounded hover:bg-opacity-80 transition-colors"
                  >
                    Edit Profile
                  </button>
                )}
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black focus:border-black transition duration-150 ease-in-out"
                        />
                      ) : (
                        user.name
                      )}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Email address</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.email}</dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Company name</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {isEditing ? (
                        <input
                          type="text"
                          value={editedCompanyName}
                          onChange={(e) => setEditedCompanyName(e.target.value)}
                          className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black focus:border-black transition duration-150 ease-in-out"
                        />
                      ) : (
                        user.company_name || 'Not specified'
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
              {isEditing && (
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    onClick={handleSaveProfile}
                    className="bg-black text-white font-bold py-2 px-4 rounded mr-2 hover:bg-opacity-80 transition-colors"
                  >
                    Save Changes
                  </button>
                  <button
                    onClick={() => {
                      setIsEditing(false);
                      setEditedName(user.name);
                      setEditedCompanyName(user.company_name || '');
                    }}
                    className="bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded hover:bg-gray-400 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}
          {/* Add placeholders for other sections */}
          {activeItem === 'API Keys' && <div>API Keys content goes here</div>}
          {activeItem === 'Usage' && <div>Usage statistics content goes here</div>}
          {activeItem === 'Billing' && <div>Billing information content goes here</div>}
          {activeItem === 'Settings' && <div>Settings content goes here</div>}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
