import React from 'react';
import { Link } from 'react-router-dom';

const GettingStarted = () => (
  <div className="space-y-6">
    <h2 className="text-2xl font-bold mb-4">Getting Started with HPKV</h2>

    <h3 className="text-xl font-bold mt-6 mb-2">Obtaining an API Key</h3>
    <p>
      To start using HPKV, you'll need an API key. Here's how to get one:
    </p>
    <ol className="list-decimal pl-6 space-y-2">
      <li>Visit the <a href="/pricing" className="text-primary hover:underline">HPKV Pricing Page</a>.</li>
      <li>Choose a plan that suits your needs and complete the purchase process.</li>
      <li>Once your purchase is confirmed, you'll receive an email with your API key.</li>
      <li>Keep this API key secure, as it will be used to authenticate all your requests to HPKV.</li>
    </ol>

    <h3 className="text-xl font-bold mt-6 mb-2">Using Your API Key</h3>
    <p>
      Your API key must be included in all requests to the HPKV API. Here's how to use it:
    </p>
    <ul className="list-disc pl-6 space-y-2">
      <li>For REST API requests, include your API key in the <code className="bg-gray-100 p-1 rounded">x-api-key</code> header.</li>
      <li>For gRPC API calls, include your API key in the metadata with the key <code className="bg-gray-100 p-1 rounded">x-api-key</code>.</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Making Your First Request</h3>
    <p>
      All API requests should be made to <code className="bg-gray-100 p-1 rounded">https://api.hpkv.io</code>. Here's a simple example of how to make a request using curl:
    </p>
    <pre className="bg-gray-100 p-4 rounded-md mb-4">
      <code className="language-bash">
{`curl -X POST https://api.hpkv.io/record \\
  -H "Content-Type: application/json" \\
  -H "x-api-key: YOUR_API_KEY" \\
  -d '{"key": "my_first_key", "value": "Hello, HPKV!"}'`}
      </code>
    </pre>
    <p>
      This request will insert a new key-value pair into your HPKV store.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Next Steps</h3>
    <p>
      Now that you have your API key and know how to make a basic request, you can explore the full capabilities of HPKV:
    </p>
    <ul className="list-disc pl-6">
      <li>Learn about all available endpoints in the <Link to="/docs/rest-api" className="text-primary hover:underline">REST API documentation</Link>.</li>
      <li>Explore the high-performance options in the <Link to="/docs/grpc-api" className="text-primary hover:underline">gRPC API documentation</Link>.</li>
      <li>Check out our <Link to="/docs/sdk-guides" className="text-primary hover:underline">SDK Guides</Link> for language-specific integration examples.</li>
      <li>Review our <Link to="/docs/best-practices" className="text-primary hover:underline">Best Practices</Link> to ensure you're using HPKV optimally.</li>
    </ul>
  </div>
);

export default GettingStarted;