import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="bg-gradient-to-r from-hero-start to-hero-end text-white py-20">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="md:w-1/2 mb-10 md:mb-0"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-6">
              High-Performance<br/>Key-Value Store
            </h1>
            <p className="text-xl mb-8">
              HPKV offers exceptional microsecond performance for datasets<br/>ranging from 10,000 to 10,000,000 records.
            </p>
            <div className="flex space-x-4">
              <Link to="/signup" className="bg-orange text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition-colors">
                Get Started
              </Link>
              <Link to="/docs" className="border-2 border-white text-white font-bold py-3 px-6 rounded-lg hover:bg-white hover:text-hero-start transition-colors">
                Documentation
              </Link>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="md:w-1/2"
          >
            <img src="/images/hpkv-illustration.svg" alt="HPKV Illustration" className="w-full" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
