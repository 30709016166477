import React from 'react';
import { Link } from 'react-router-dom';

const Overview = () => (
  <div className="space-y-6">
    <h2 className="text-2xl font-bold mb-4">HPKV API Overview</h2>
    <p>
      HPKV offers two powerful API options for interacting with our high-performance key-value store: REST API and gRPC API. Each API type has its own strengths and is suitable for different use cases.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">REST API</h3>
    <p>
      Our REST API provides a simple, HTTP-based interface for interacting with HPKV. It's ideal for:
    </p>
    <ul className="list-disc pl-6">
      <li>Web applications and services</li>
      <li>Mobile app backends</li>
      <li>Integration with existing HTTP-based systems</li>
      <li>Scenarios where broad language and framework support is needed</li>
    </ul>
    <p>
      The REST API supports all basic operations such as inserting, retrieving, updating, and deleting key-value pairs, as well as fetching statistics about your HPKV usage.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">gRPC API</h3>
    <p>
      Our gRPC API offers a high-performance, low-latency interface for HPKV operations. It's particularly well-suited for:
    </p>
    <ul className="list-disc pl-6">
      <li>Microservices architectures</li>
      <li>High-throughput systems</li>
      <li>Scenarios requiring bi-directional streaming</li>
      <li>Applications where performance is critical</li>
    </ul>
    <p>
      The gRPC API provides the same core functionality as the REST API but with the added benefits of protocol buffers for efficient serialization and HTTP/2 for improved performance.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Choosing the Right API</h3>
    <p>
      When deciding between REST and gRPC, consider the following factors:
    </p>
    <ul className="list-disc pl-6">
      <li>Performance requirements: If you need the absolute highest performance, gRPC is generally faster.</li>
      <li>Language support: While both APIs have broad language support, REST might be easier to integrate with certain languages or frameworks.</li>
      <li>Ecosystem compatibility: If you're integrating with systems that expect REST APIs, the REST option might be more straightforward.</li>
      <li>Learning curve: REST is more widely known and might be easier for teams already familiar with HTTP-based APIs.</li>
    </ul>

    <p className="mt-4">
      For more detailed information on each API, including endpoints, request/response formats, and code examples, please refer to the <Link to="/docs/rest-api" className="text-primary hover:underline">REST API</Link> and <Link to="/docs/grpc-api" className="text-primary hover:underline">gRPC API</Link> documentation sections.
    </p>
  </div>
);

export default Overview;