import React, { useEffect, useRef } from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Pricing from '../components/Pricing';

const Home = () => {
  const homeRef = useRef(null);

  useEffect(() => {
    const scrollDown = () => {
      const header = document.querySelector('header');
      if (header && homeRef.current) {
        const headerHeight = header.offsetHeight;
        window.scrollTo({
          top: headerHeight,
          behavior: 'smooth'
        });
      }
    };

    // Small delay to ensure the DOM is fully loaded
    const timeoutId = setTimeout(scrollDown, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div ref={homeRef}>
      <Hero />
      <Features />
      <Pricing />
    </div>
  );
};

export default Home;
