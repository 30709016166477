import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SdkGuides = () => (
  <div className="space-y-8">
    <h2 className="text-2xl font-bold mb-4">SDK Guides</h2>
    <p>
      HPKV provides SDKs for various programming languages to simplify integration with your applications. These SDKs abstract away the complexities of making direct API calls, providing a more intuitive and language-specific interface.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">.NET SDK (C#)</h3>
    <p>To install the .NET SDK:</p>
    <SyntaxHighlighter language="bash" style={vscDarkPlus}>
      dotnet add package HPKV.NET
    </SyntaxHighlighter>
    <p>Example usage:</p>
    <SyntaxHighlighter language="csharp" style={vscDarkPlus}>
      {`using HPKV;

var client = new HPKVClient("your-api-key");

// Insert a record
await client.InsertAsync("example_key", "example_value");

// Get a record
var value = await client.GetAsync("example_key");
Console.WriteLine($"Value: {value}");

// Delete a record
await client.DeleteAsync("example_key");

// Get statistics
var stats = await client.GetStatsAsync();
Console.WriteLine($"Stats: {string.Join(", ", stats)}");

// Partial update
await client.InsertAsync("example_key", "initial value");
await client.InsertAsync("example_key", "updated", partialUpdate: true);
Console.WriteLine(await client.GetAsync("example_key"));  // Output: "updated value"`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">Python SDK</h3>
    <p>To install the Python SDK:</p>
    <SyntaxHighlighter language="bash" style={vscDarkPlus}>
      pip install hpkv-python
    </SyntaxHighlighter>
    <p>Example usage:</p>
    <SyntaxHighlighter language="python" style={vscDarkPlus}>
      {`from hpkv import HPKVClient

client = HPKVClient("your-api-key")

# Insert a record
client.insert("example_key", "example_value")

# Get a record
value = client.get("example_key")
print(f"Value: {value}")

# Delete a record
client.delete("example_key")

# Get statistics
stats = client.get_stats()
print(f"Stats: {stats}")

# Partial update
client.insert("example_key", "initial value")
client.insert("example_key", "updated", partial_update=True)
print(client.get("example_key"))  # Output: "updated value"`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">JavaScript SDK</h3>
    <p>To install the JavaScript SDK:</p>
    <SyntaxHighlighter language="bash" style={vscDarkPlus}>
      npm install hpkv-js
    </SyntaxHighlighter>
    <p>Example usage:</p>
    <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
      {`const HPKV = require('hpkv-js');

const client = new HPKV.Client('your-api-key');

async function exampleUsage() {
  // Insert a record
  await client.insert('example_key', 'example_value');

  // Get a record
  const value = await client.get('example_key');
  console.log('Value:', value);

  // Delete a record
  await client.delete('example_key');

  // Get statistics
  const stats = await client.getStats();
  console.log('Stats:', stats);

  // Partial update
  await client.insert('example_key', 'initial value');
  await client.insert('example_key', 'updated', { partialUpdate: true });
  console.log(await client.get('example_key'));  // Output: "updated value"
}

exampleUsage().catch(console.error);`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">Java SDK</h3>
    <p>To install the Java SDK, add the following dependency to your pom.xml:</p>
    <SyntaxHighlighter language="xml" style={vscDarkPlus}>
      {`<dependency>
    <groupId>io.hpkv</groupId>
    <artifactId>hpkv-java</artifactId>
    <version>1.0.0</version>
</dependency>`}
    </SyntaxHighlighter>
    <p>Example usage:</p>
    <SyntaxHighlighter language="java" style={vscDarkPlus}>
      {`import io.hpkv.HPKVClient;
import io.hpkv.HPKVException;

public class HPKVExample {
    public static void main(String[] args) {
        HPKVClient client = new HPKVClient("your-api-key");

        try {
            // Insert a record
            client.insert("example_key", "example_value");

            // Get a record
            String value = client.get("example_key");
            System.out.println("Value: " + value);

            // Delete a record
            client.delete("example_key");

            // Get statistics
            Map<String, String> stats = client.getStats();
            System.out.println("Stats: " + stats);

            // Partial update
            client.insert("example_key", "initial value");
            client.insert("example_key", "updated", true);
            System.out.println(client.get("example_key"));  // Output: "updated value"

        } catch (HPKVException e) {
            System.err.println("HPKV operation failed: " + e.getMessage());
        }
    }
}`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">Best Practices for Using SDKs</h3>
    <ul className="list-disc pl-6">
      <li>Always handle exceptions and errors properly to ensure your application can gracefully handle HPKV service disruptions or network issues.</li>
      <li>Use connection pooling when available to improve performance for high-throughput applications.</li>
      <li>Implement retry logic with exponential backoff for transient errors.</li>
      <li>Keep your API key secure and never hard-code it in your source files. Use environment variables or secure configuration management systems.</li>
      <li>Monitor your usage and set up alerts to avoid hitting rate limits unexpectedly.</li>
      <li>Regularly update your SDK to the latest version to benefit from bug fixes and performance improvements.</li>
    </ul>
  </div>
);

export default SdkGuides;