import React from 'react';
import { motion } from 'framer-motion';
import { CheckIcon } from '@heroicons/react/solid';

const tiers = [
  {
    name: 'Free Tier',
    price: 0,
    features: [
      'Up to 1000 records',
      '100 requests/hour',
      'Shared infrastructure',
      'Basic support',
      'API access',
    ],
  },
  {
    name: 'Starter',
    price: 69,
    features: [
      'Up to 10 million records',
      '1 million requests/hour',
      'Shared infrastructure',
      'Priority support',
      'API access',
      'Advanced analytics',
    ],
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    features: [
      'Unlimited records',
      'Unlimited requests/hour',
      'Dedicated infrastructure',
      '24/7 dedicated support',
      'API access',
      'Advanced analytics',
      'Custom integrations',
    ],
  },
];

const Pricing = () => {
  return (
    <div className="py-20 bg-background text-text">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-primary"
        >
          Pricing Plans
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {tiers.map((tier, index) => (
            <motion.div
              key={tier.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-component-bg border border-secondary rounded-lg shadow-md p-8 flex flex-col h-full"
            >
              <div className="flex-grow">
                <h3 className="text-2xl font-bold mb-4 text-primary">{tier.name}</h3>
                <p className="text-4xl font-bold mb-6 text-primary">
                  {typeof tier.price === 'number' ? `$${tier.price}/mo` : tier.price}
                </p>
                <ul className="mb-8">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-center mb-2">
                      <CheckIcon className="h-5 w-5 text-primary mr-2" />
                      <span className="text-text">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <button className="w-full bg-primary text-button-text font-bold py-2 px-4 rounded hover:bg-secondary transition-colors mt-auto">
                {tier.name === 'Enterprise' ? 'Contact Sales' : 'Get Started'}
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
