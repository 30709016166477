import React from 'react';
import { motion } from 'framer-motion';
import { LightningBoltIcon, ChartBarIcon, DatabaseIcon, ClockIcon, RefreshIcon } from '@heroicons/react/outline';

const features = [
  {
    name: 'High Performance',
    description: 'Exceptional read and write speeds for datasets up to 10 million records.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Immediate Consistency',
    description: 'All reads reflect the latest writes, providing real-time data accuracy.',
    icon: RefreshIcon,
  },
  {
    name: 'Data Persistence',
    description: 'Combines in-memory speed with disk storage durability, without sacrificing performance.',
    icon: DatabaseIcon,
  },
  {
    name: 'Low Latency',
    description: 'Microsecond-level read/write latencies, even as your dataset grows.',
    icon: ClockIcon,
  },
  {
    name: 'Scalability',
    description: 'Designed to handle growing data needs with minimal performance degradation.',
    icon: ChartBarIcon,
  },
];

const Features = () => {
  return (
    <div className="py-20 bg-background text-text">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-primary"
        >
          Key Features of HPKV
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
          {features.map((feature, index) => (
            <motion.div
              key={feature.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-component-bg border border-secondary rounded-lg shadow-md p-4"
            >
              <feature.icon className="h-8 w-8 text-primary mb-2" />
              <h3 className="text-lg font-semibold mb-1 text-primary">{feature.name}</h3>
              <p className="text-sm text-text">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
