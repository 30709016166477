import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('user');
      setIsLoggedIn(!!token && !!userData);
    };

    checkLoginStatus();
    window.addEventListener('storage', checkLoginStatus);
    window.addEventListener('userLoggedIn', checkLoginStatus);

    return () => {
      window.removeEventListener('storage', checkLoginStatus);
      window.removeEventListener('userLoggedIn', checkLoginStatus);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <header className="bg-component-bg shadow-md">
      <nav className="container mx-auto px-6 py-3">
        <div className="flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/" className="flex items-center">
              <img src="/images/hpkv-illustration-black.svg" alt="HPKV Logo" className="h-12 w-auto" />
            </Link>
          </motion.div>
          <div className="hidden md:flex items-center">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="flex items-center"
            >
              <Link to="/docs" className="mx-4 text-text hover:text-primary transition-colors">Docs</Link>
              <Link to="/pricing" className="mx-4 text-text hover:text-primary transition-colors">Pricing</Link>
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard" className="mx-4 text-text hover:text-primary transition-colors">Dashboard</Link>
                  <button onClick={handleLogout} className="mx-4 bg-primary text-button-text px-4 py-2 rounded-md hover:bg-secondary transition-colors">Logout</button>
                </>
              ) : (
                <>
                  <Link to="/login" className="mx-4 text-text hover:text-primary transition-colors">Login</Link>
                  <Link to="/signup" className="mx-4 bg-primary text-button-text px-4 py-2 rounded-md hover:bg-secondary transition-colors">Sign Up</Link>
                </>
              )}
            </motion.div>
          </div>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className="text-text hover:text-primary transition-colors">
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isOpen ? (
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                ) : (
                  <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                )}
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden mt-4"
          >
            <Link to="/docs" className="block py-2 text-text hover:text-primary transition-colors">Docs</Link>
            <Link to="/pricing" className="block py-2 text-text hover:text-primary transition-colors">Pricing</Link>
            {isLoggedIn ? (
              <>
                <Link to="/dashboard" className="block py-2 text-text hover:text-primary transition-colors">Dashboard</Link>
                <button onClick={handleLogout} className="block w-full text-left py-2 bg-primary text-button-text px-4 rounded-md hover:bg-secondary transition-colors">Logout</button>
              </>
            ) : (
              <>
                <Link to="/login" className="block py-2 text-text hover:text-primary transition-colors">Login</Link>
                <Link to="/signup" className="block py-2 bg-primary text-button-text px-4 rounded-md hover:bg-secondary transition-colors">Sign Up</Link>
              </>
            )}
          </motion.div>
        )}
      </nav>
    </header>
  );
};

export default Header;
